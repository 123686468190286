var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"eedit-exam-sidebar","centered":"","visible":_vm.isEditExamSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","size":"lg","backdrop":"","no-header":"","right":"","ok-title":_vm.$t('ok'),"ok-only":"","title":_vm.$t('editAnimalExam')},on:{"ok":function($event){return _vm.onSubmit(_vm.blankExamData)},"hidden":function($event){_vm.formValidation(_vm.resetblankexam).resetForm},"change":function (val) { return _vm.changed(val); }}},[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{ref:"refForm",staticClass:"p-2 modal-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();_vm.formValidation(_vm.resetblankexam).resetForm}}},[_c('button',{ref:"submitButtonRef",staticClass:"d-none",attrs:{"type":"submit"}}),_c('validation-provider',{attrs:{"name":"examdate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('examDateTime'),"label-for":"examdate"}},[_c('b-form-datepicker',{attrs:{"locale":_vm.$i18n.locale},model:{value:(_vm.blankExamData.examdate),callback:function ($$v) {_vm.$set(_vm.blankExamData, "examdate", $$v)},expression:"blankExamData.examdate"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"examiner","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('examiner'),"label-for":"examiner"}},[_c('b-form-input',{attrs:{"id":"examiner","autofocus":"","state":_vm.formValidation(_vm.resetblankexam).getValidationState(
                  validationContext
                ),"trim":"","placeholder":""},model:{value:(_vm.blankExamData.examiner),callback:function ($$v) {_vm.$set(_vm.blankExamData, "examiner", $$v)},expression:"blankExamData.examiner"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"condition"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('condition'),"label-for":"condition"}},[_c('b-form-input',{attrs:{"id":"condition","autofocus":"","type":"number","state":_vm.formValidation(_vm.resetblankexam).getValidationState(
                  validationContext
                ),"trim":"","placeholder":"","step":"0.01"},model:{value:(_vm.blankExamData.condition),callback:function ($$v) {_vm.$set(_vm.blankExamData, "condition", $$v)},expression:"blankExamData.condition"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"weight"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('weight'),"label-for":"weight"}},[_c('b-form-input',{attrs:{"id":"weight","autofocus":"","type":"number","state":_vm.formValidation(_vm.resetblankexam).getValidationState(
                  validationContext
                ),"trim":"","placeholder":"","step":"0.01"},model:{value:(_vm.blankExamData.weight),callback:function ($$v) {_vm.$set(_vm.blankExamData, "weight", $$v)},expression:"blankExamData.weight"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"diagnosis"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":_vm.$t('diagnosis'),"label-for":"diagnosis"}},[_c('b-form-textarea',{attrs:{"id":"diagnosis","autofocus":"","type":"text","state":_vm.formValidation(_vm.resetblankexam).getValidationState(
                  validationContext
                ),"trim":"","placeholder":""},model:{value:(_vm.blankExamData.diagnosis),callback:function ($$v) {_vm.$set(_vm.blankExamData, "diagnosis", $$v)},expression:"blankExamData.diagnosis"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }