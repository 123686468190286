<template>
  <div>
    <b-modal
      id="eedit-exam-sidebar"
      centered
      :visible="isEditExamSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      size="lg"
      backdrop
      no-header
      right
      :ok-title="$t('ok')"
      ok-only
      :title="$t('editAnimalExam')"
      @ok="onSubmit(blankExamData)"
      @hidden="formValidation(resetblankexam).resetForm"
      @change="(val) => changed(val)"
    >
      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->

        <b-form
          ref="refForm"
          class="p-2 modal-form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="formValidation(resetblankexam).resetForm"
        >
          <button ref="submitButtonRef" type="submit" class="d-none" />

          <!-- examdate -->
          <validation-provider
            #default="validationContext"
            name="examdate"
            rules="required"
          >
            <b-form-group :label="$t('examDateTime')" label-for="examdate">
              <b-form-datepicker
                v-model="blankExamData.examdate"
                :locale="$i18n.locale"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="examiner"
            rules="required"
          >
            <b-form-group :label="$t('examiner')" label-for="examiner">
              <b-form-input
                id="examiner"
                v-model="blankExamData.examiner"
                autofocus
                :state="
                  formValidation(resetblankexam).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- condition -->
          <validation-provider #default="validationContext" name="condition">
            <b-form-group :label="$t('condition')" label-for="condition">
              <b-form-input
                id="condition"
                v-model="blankExamData.condition"
                autofocus
                type="number"
                :state="
                  formValidation(resetblankexam).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
                step="0.01"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- weight -->
          <validation-provider #default="validationContext" name="weight">
            <b-form-group :label="$t('weight')" label-for="weight">
              <b-form-input
                id="weight"
                v-model="blankExamData.weight"
                autofocus
                type="number"
                :state="
                  formValidation(resetblankexam).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
                step="0.01"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- diagnosis -->
          <validation-provider #default="validationContext" name="diagnosis">
            <b-form-group :label="$t('diagnosis')" label-for="diagnosis">
              <b-form-textarea
                id="diagnosis"
                v-model="blankExamData.diagnosis"
                autofocus
                type="text"
                :state="
                  formValidation(resetblankexam).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
  BFormDatepicker,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormDatepicker,
    BFormTextarea,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditExamSidebarActive",
    event: "update:is-edit-exam-sidebar-active",
  },
  props: {
    isEditExamSidebarActive: {
      type: Boolean,
      required: true,
    },
    exam: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      blankExamData: { ...this.exam },
      imgStr: "",
      formValidation: formValidation,
      profileFile: null,
      picturechanged: false,
      refInputEl: null,
      previewEl: null,
      inputImageRenderer: null,
      examtypeOptions: [],
      selectedExamType: null,
    };
  },
  mounted() {},
  async created() {},
  methods: {
    async changed(val) {
      this.resetblankexam();
      this.$emit("update:is-edit-exam-sidebar-active", val);
    },
    onSubmit(examData) {
      store.dispatch("animalsModule/updateAnimalExam", examData).then(() => {
        this.$emit("refetch-data");
        this.$emit("update:is-edit-exam-sidebar-active", false);
      });
    },
    async resetblankexam() {
      this.blankExamData = {
        ...this.exam,
        examid: this.exam.id,
      };
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#edit-exam-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
