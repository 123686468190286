<template>
  <div>
    <b-modal
      id="add-new-exam-sidebar"
      centered
      :visible="isAddNewExamSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      shadow
      size="lg"
      backdrop
      no-header
      right
      :title="$t('examAdd')"
      :ok-title="$t('ok')"
      ok-only
      @ok="onSubmit(blankExam)"
      @hidden="resetForm"
      @change="(val) => $emit('update:is-add-new-exam-sidebar-active', val)"
    >
      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->

        <b-form
          ref="refForm"
          class="p-2 modal-form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <button ref="submitButtonRef" type="submit" class="d-none" />
          <!-- examdate -->
          <validation-provider
            #default="validationContext"
            name="examdate"
            rules="required"
          >
            <b-form-group :label="$t('examDateTime')" label-for="examdate">
              <b-form-datepicker
                v-model="blankExam.examdate"
                :locale="$i18n.locale"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="examiner"
            rules="required"
          >
            <b-form-group :label="$t('examiner')" label-for="examiner">
              <b-form-input
                id="examiner"
                v-model="blankExam.examiner"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- condition -->
          <validation-provider #default="validationContext" name="condition">
            <b-form-group :label="$t('condition')" label-for="condition">
              <b-form-input
                id="condition"
                v-model="blankExam.condition"
                autofocus
                type="number"
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                step="0.01"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- weight -->
          <validation-provider #default="validationContext" name="weight">
            <b-form-group :label="$t('weight')" label-for="weight">
              <b-form-input
                id="weight"
                v-model="blankExam.weight"
                autofocus
                type="number"
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                step="0.01"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- diagnosis -->
          <validation-provider #default="validationContext" name="diagnosis">
            <b-form-group :label="$t('diagnosis')" label-for="diagnosis">
              <b-form-textarea
                id="diagnosis"
                v-model="blankExam.diagnosis"
                autofocus
                type="text"
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormDatepicker,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, getCurrentInstance } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getUserData } from "@/auth/utils";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormDatepicker,
    BFormTextarea,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewExamSidebarActive",
    event: "update:is-add-new-exam-sidebar-active",
  },
  props: {
    isAddNewExamSidebarActive: {
      type: Boolean,
      required: true,
    },
    selectedAnimal: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    };
  },

  setup(props, { emit }) {
    const vm = getCurrentInstance();
    const dayJs = dayjs.extend(relativeTime);

    const blankExamData = {
      id: 0,
      companyid: getUserData().companyid,
      plantid: store.state.app.selectedPlantId,
      animalid: props.selectedAnimal.id,
      examdate: dayJs().format(),
      condition: 0,
      diagnosis: "",
      examiner: "",
      weight: "",
    };

    const blankExam = ref(JSON.parse(JSON.stringify(blankExamData)));
    const resetblankExam = () => {
      blankExam.value = JSON.parse(JSON.stringify(blankExamData));
    };

    const onSubmit = (exam) => {
      store.dispatch("animalsModule/addAnimalExam", exam).then((res) => {
        if (res.statusdata == "-2") {
          vm.proxy.$toast({
            component: ToastificationContent,
            props: {
              title: vm.proxy.$t("animalAlreadyAdded"),
              icon: "AlertCircleIcon",
              variant: "alert",
            },
          });
        } else if (res.statusdata == "1") {
          vm.proxy.$toast({
            component: ToastificationContent,
            props: {
              title: vm.proxy.$t("animalAdded"),
              icon: "AlertCircleIcon",
              variant: "success",
            },
          });
          emit("refetch-data");
          emit("update:is-add-new-exam-sidebar-active", false);
        } else {
          vm.proxy.$toast({
            component: ToastificationContent,
            props: {
              title: vm.proxy.$t("animalExamNotAdded"),
              icon: "AlertCircleIcon",
              variant: "success",
            },
          });
        }
      });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetblankExam);
    return {
      blankExam,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-exam-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.sidebar-xl-scoped {
  width: 70rem;
}
</style>
