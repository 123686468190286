import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import VueI18n from "@/libs/i18n";

import dayjs from "dayjs";
import "dayjs/locale/tr";
import "dayjs/locale/en";
import "dayjs/locale/ru";
import "dayjs/locale/az";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useExamList() {
  // Use toast
  const toast = useToast();

  const refExamListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    {
      key: "examdate",
      label: VueI18n.t("examDateTime"),
      sortable: false,
      formatter: (val) =>
        `${dayjs(val).locale(`${VueI18n.locale}`).format("DD MMMM YYYY dddd")}`,
    },

    {
      key: "examiner",
      label: VueI18n.t("examiner"),
      sortable: false,
    },
    {
      key: "condition",
      label: VueI18n.t("condition"),
      sortable: false,
    },
    {
      key: "weight",
      label: VueI18n.t("weight"),
      sortable: false,
    },
    { key: "delete", label: VueI18n.t("delete") },
    { key: "edit", label: VueI18n.t("edit") },
  ];
  const perPage = ref(10);
  const totalExam = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);
  const allExams = ref([]);
  const filteredExams = ref([]);

  const dataMeta = computed(() => {
    const localItemsCount = filteredExams.value.length;
    const to = perPage.value * currentPage.value < localItemsCount;

    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: to
        ? perPage.value * (currentPage.value - 1) + perPage.value
        : localItemsCount,
      of: allExams.value.length,
    };
  });

  const refetchData = () => {
    if (searchQuery.value) {
      filteredExams.value = allExams.value.filter((x) =>
        x.examname.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
    } else {
      filteredExams.value = allExams.value;
    }
  };

  watch(
    [currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter],
    () => {
      refetchData();
    }
  );

  const fetchExams = async (ctx) => {
    var exam = await store
      .dispatch("animalsModule/fetchAnimalExams", ctx)
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            text: VueI18n.t("xFetchError", {
              value: VueI18n.t("exams"),
            }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    allExams.value = exam;
    filteredExams.value = [...exam];
    refetchData();

    return exam;
  };

  const deleteExam = (ctx) => {
    store
      .dispatch("animalsModule/deleteAnimalExam", ctx.examid)
      .then((response) => {
        fetchExams(ctx.animalid);
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: VueI18n.t("deleted", { type: VueI18n.t("exams") }),
          },
        });
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            text: VueI18n.t("xFetchError", {
              value: VueI18n.t("exams"),
            }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // *===============================================---*
  // *--------------------- UI -------------------------*
  // *===============================================---*

  return {
    fetchExams,
    tableColumns,
    perPage,
    currentPage,
    totalExam,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refExamListTable,
    deleteExam,
    filteredExams,
    allExams,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  };
}
